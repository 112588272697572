// @file Zoom realtime store
import { trackEvent } from '@@/bits/analytics'
import { getSocket } from '@@/bits/brahms'
import currentUser from '@@/bits/current_user'
import window, { ww } from '@@/bits/global'
import { navigateTo } from '@@/bits/location'
import { initializeZoomSdk, registerAsCollaborationHost, unregisterAsCollaborationHost } from '@@/bits/zoom'
import type { RealtimeState } from '@@/vuexstore/modules/realtime'
import type { OnCollaborateChangeEvent } from '@zoom/appssdk'
import type * as Phoenix from 'phoenix'
import { defineStore } from 'pinia'
import { ref, watch } from 'vue'

interface RealtimeMessage {
  event?: string
  uid?: string
  message?: any
}

export const useZoomRealtimeStore = defineStore('zoomRealtime', () => {
  const brahmsToken = ref<string | undefined>(undefined)
  const socket = ref<Phoenix.Socket | null>(null)
  const channel = ref<Phoenix.Channel | undefined>(undefined)
  const isUserHostingZoomCollaboration = ref<boolean | undefined>(undefined)
  const cannotAccessPadlet = ref<boolean>(false)
  const isInCollaborateMode = ref<boolean | null>(null)

  const checkShouldNavigateToUrl = (url: string): boolean => {
    const currentUrl = window.location.href
    if (window.location.href === url) return false
    const isZoomFrontPage = (url: string): boolean => url.includes('/zoom/landing') || url.includes('/zoom/dashboard')
    if (isZoomFrontPage(currentUrl) && isZoomFrontPage(url)) return false
    return true
  }

  const newMessageCallback = (message: RealtimeMessage): void => {
    if (isInCollaborateMode.value == null || !isInCollaborateMode.value) return
    if (isUserHostingZoomCollaboration.value === true) return
    const urlToNavigate = message.message.url
    if (!checkShouldNavigateToUrl(urlToNavigate)) return
    navigateTo(urlToNavigate)
  }

  // Action
  async function initializeForSurface(): Promise<void> {
    const surfaceVuexStore = window.app?.$store // For gradual conversion to pinia

    // Get realtime state and brahms socket from vue starting state
    const realtime = surfaceVuexStore?.state.realtime as RealtimeState
    socket.value = realtime.brahmsSocket

    // Check if zoom meeting ID is valid, otherwise return
    const zoomMeetingId = surfaceVuexStore?.state.zoomMeetingId as string
    if (zoomMeetingId == null) return
    isUserHostingZoomCollaboration.value = surfaceVuexStore?.state.isUserHostingZoomCollaboration

    await initializeCollaborateModeStatusAndListeners()

    // Listen to zoom socket, navigate to url provided by message if participant in collaborate mode
    channel.value = socket.value?.channel(`zoom:${zoomMeetingId}`)
    channel.value?.on('new_msg', newMessageCallback)
    channel.value?.join()

    // If host, push a event that will go to handle_in that broadcasts to the socket
    if (
      isInCollaborateMode.value != null &&
      isInCollaborateMode.value &&
      isUserHostingZoomCollaboration.value === true
    ) {
      channel.value?.push('navigate', { url: window.location.href })
    }
  }

  async function initializeForZoomDashboardOrLanding(): Promise<void> {
    // Get meeting id and brahms token from vue starting state, return if zoomMeetingId is null
    const zoomMeetingId = ww.vueStartingState.zoomMeetingId as string | null
    brahmsToken.value = ww.vueStartingState.brahmsToken as string
    if (zoomMeetingId == null) return

    // Get brahms socket from token and connect, return if socket is null
    socket.value = getSocket({ accessToken: brahmsToken.value }) ?? null
    if (socket.value == null) return
    socket.value?.connect()

    // Set isUserHostingZoomCollaboration and cannotAccessPadlet value from vue starting state, used in newMessageCallback and message publishing
    isUserHostingZoomCollaboration.value = ww.vueStartingState.isUserHostingZoomCollaboration
    cannotAccessPadlet.value = ww.vueStartingState.cannotAccessPadlet

    await initializeCollaborateModeStatusAndListeners()

    // Listen to zoom socket, navigate to url provided by message if participant in collaborate mode
    channel.value = socket.value?.channel(`zoom:${zoomMeetingId}`)
    channel.value?.on('new_msg', newMessageCallback)
    channel.value?.join()

    // If host, push a event that will go to handle_in that broadcasts to the socket
    if (
      isInCollaborateMode.value != null &&
      isInCollaborateMode.value &&
      isUserHostingZoomCollaboration.value === true
    ) {
      channel.value?.push('navigate', { url: '/zoom/dashboard' })
    }
  }

  watch(isInCollaborateMode, (newValue: boolean | null) => {
    if (newValue != null && newValue && isUserHostingZoomCollaboration.value === true) {
      channel?.value?.push('navigate', { url: window.location.href })
    }
  })

  function updateCollaborateModeStatus(status: boolean): void {
    isInCollaborateMode.value = status
  }

  async function initializeCollaborateModeStatusAndListeners(): Promise<void> {
    await initializeZoomSdk()
    const zoomSdk = (await import('@zoom/appssdk')).default
    const runningContext = await zoomSdk.getRunningContext()
    if (runningContext.context === 'inCollaborate') {
      updateCollaborateModeStatus(true)
    } else {
      updateCollaborateModeStatus(false)
    }
    zoomSdk.onCollaborateChange((status) => {
      void handleCollaborateChange(status)
    })

    const handleCollaborateChange = async (status: OnCollaborateChangeEvent): Promise<void> => {
      if (status.action === 'start') {
        trackEvent('Zoom Collaborate Start', 'Host', currentUser.id)
        void registerAsCollaborationHost()
      } else if (['join', 'leave', 'end'].includes(status.action)) {
        void unregisterAsCollaborationHost()
      }
      updateCollaborateModeStatus(status.action === 'start' || status.action === 'join')
    }
  }

  return {
    // actions
    initializeForZoomDashboardOrLanding,
    initializeForSurface,
    updateCollaborateModeStatus,
    isInCollaborateMode,
    isUserHostingZoomCollaboration,
    cannotAccessPadlet,
  }
})
